import { addQueryParams } from 'lib/url'

export const nextApiRoutes = {
    postContractSignedRedirect: (args: { invoiceId: string }) =>
        addQueryParams('/api/esignatures/post-contract-signed-redirect', args),
}

const esignaturesApiRoutes = {
    contracts: (args?: { id?: string }) =>
        args?.id ? `/contracts/${args.id}` : '/contracts',
}

export const routes = {
    ...esignaturesApiRoutes,
    ...nextApiRoutes,
}
