import { progressAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    progressAnatomy.keys,
)

const baseStyleFilledTrack = defineStyle({
    bgColor: 'bg.neon10',
})

const baseStyleTrack = defineStyle({
    bgColor: 'transparent',
    borderRadius: '4px',
    boxShadow: 'inset 0px 0px 0px 1px #1223151a',
})

const sizes = {
    md: definePartsStyle({
        track: { h: '32px' },
    }),
    xs: definePartsStyle({
        track: { h: '8px' },
    }),
}

const baseStyle = definePartsStyle(props => ({
    filledTrack: baseStyleFilledTrack,
    track: baseStyleTrack,
}))

const variants = {
    'risk-metric-indicator': defineStyle(() => {
        return definePartsStyle({
            filledTrack: {
                bgColor: 'orange.100',
                h: '8px',
            },
            track: {
                bgColor: 'green.transparent_10',
                boxShadow: 'none',
                borderRadius: '0px',
                h: '8px',
            },
        })
    }),
    'pending-deduction': defineStyle(() => {
        return definePartsStyle({
            filledTrack: {
                bgColor: 'green.100',
                borderColor: 'bg.neon10',
                borderWidth: '1px',
            },
        })
    }),
    'funding-offered': defineStyle(() => {
        return definePartsStyle({
            filledTrack: {
                bgColor: 'blue.100',
            },
            track: {
                bgColor: 'blue.transparent_10',
                boxShadow: 'none',
                borderRadius: '0px',
            },
        })
    }),
    'invoice-parsing': defineStyle(() => {
        return definePartsStyle({
            filledTrack: {
                bgColor: 'neon.100',
                border: 'none',
            },
            track: {
                bgColor: 'green.transparent_10',
                boxShadow: 'none',
                borderRadius: '0px',
            },
        })
    }),
}

export const Progress = defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
        size: 'md',
    },
    variants,
})
