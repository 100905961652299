import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react'

const variants = {
    adminCardWrapper: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            py: '16px',
            px: '20px',
            bgColor: colors.beige.darkened,
            minH: '224px',
            borderRadius: '10px',
        }
    }),
}

export const CardWrapper: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: '20px',
        padding: '34px',
        backgroundColor: 'white',
    },
    variants,
}
