import { ComponentStyleConfig } from '@chakra-ui/react'

export const Modal: ComponentStyleConfig = {
    baseStyle: {
        dialog: {
            bg: 'beige.background',
        },
        header: {
            fontSize: '18px', // heading-xs
            fontWeight: 400, // heading-xs
            lineHeight: '30px', // heading-xs
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
        },
    },
}
