import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    tableAnatomy.keys,
)

const variants = {
    rounded: defineStyle(() => {
        return definePartsStyle({
            thead: {
                bgColor: 'beige.darkened',
                th: {
                    py: '18px',
                    px: '20px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: 'captions.light',
                    fontFamily: 'Graphik',
                    letterSpacing: 'inherit',
                    '&:first-of-type': {
                        borderTopLeftRadius: '8px',
                    },
                    '&:last-of-type': {
                        borderTopRightRadius: '8px',
                    },
                },
            },
            tbody: {
                bgColor: 'white',
                borderTopRightRadius: '8px',
                tr: {
                    cursor: 'pointer',
                    _hover: {
                        bgColor: 'beige.highlighted',
                    },
                    '&:not(:last-of-type)': {
                        td: {
                            borderBottom: '1px solid',
                            borderColor: 'green.transparent_10',
                        },
                    },
                },
            },
            tr: {
                '&:last-of-type': {
                    td: {
                        '&:first-of-type': {
                            borderBottomLeftRadius: '8px',
                        },
                        '&:last-of-type': {
                            borderBottomRightRadius: '8px',
                        },
                    },
                },
            },
            td: {
                p: '20px',
                fontSize: '18px',
                lineHeight: '28px',
            },
        })
    }),
}

export const Table = defineMultiStyleConfig({
    variants,
})
