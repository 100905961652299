import { ComponentStyleConfig } from '@chakra-ui/react'
import { cssVar } from '@chakra-ui/theme-tools'
import { colors } from 'theme/colors'

const $startColor = cssVar('skeleton-start-color')
const $endColor = cssVar('skeleton-end-color')

export const Skeleton: ComponentStyleConfig = {
    baseStyle: {
        [$startColor.variable]: colors.beige.darkened,
        [$endColor.variable]: colors.beige.darkened_shade,
    },
    variants: {
        rounded: {
            borderRadius: '10px',
        },
    },
}
