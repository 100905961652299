import { alertAnatomy } from '@chakra-ui/anatomy'
import { defineStyle, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    alertAnatomy.keys,
)

const baseStyle = defineStyle({
    fontFamily: 'Graphik',
    title: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 400,
    },
    container: {},
})

const lg = defineStyle({
    fontSize: '20px',
    lineHeight: '28px',
})

const sm = defineStyle({
    fontSize: '16px !important',
    lineHeight: '24px',
})

const sizes = {
    sm: definePartsStyle({ description: sm }),
    lg: definePartsStyle({ description: lg }),
}

const variants = {
    info: defineStyle({
        container: {
            bgColor: 'blue.50',
        },
        icon: {
            color: 'blue.80',
        },
    }),
    success: defineStyle({
        container: {
            bgColor: 'neon.50',
        },
        icon: {
            color: 'neon.100',
        },
    }),
    warning: defineStyle({
        container: {
            bgColor: 'yellow.50',
        },
        icon: {
            color: 'yellow.100',
        },
    }),
    danger: defineStyle({
        container: {
            bgColor: 'orange.80',
        },
        icon: {
            color: 'orange.150',
        },
    }),
}

export const Alert = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        variant: 'info',
        colorScheme: 'primary',
        size: 'lg',
    },
})
