import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

// baseStyle
const baseStyle = defineStyle({
    textDecoration: 'none !important',
    fontWeight: 500,
    borderRadius: '10px',
    transition: 'background 0.3s, color 0.3s, border 0.3s',
    textTransform: 'uppercase',
    _disabled: {
        cursor: 'not-allowed',
    },
    _readOnly: {
        pointerEvents: 'none',
    },
})

// sizes
const sizes = {
    xs: defineStyle({
        fontSize: '13px',
        lineHeight: '20px',
        height: '36px',
        padding: '8px 12px',
    }),
    sm: defineStyle({
        fontSize: '16px',
        lineHeight: '20px',
        height: '44px',
        padding: '12px 18px',
    }),
    lg: defineStyle({
        fontSize: '18px',
        lineHeight: '30px',
        height: '56px',
        padding: '13px 24px',
    }),
}

// variants
const variants = {
    primary: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.green.default,
            backgroundColor: colors.neon['100'],
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.neon['100'],
            _focus: {
                outlineWidth: '3px',
                outlineColor: colors.neon['50'],
                backgroundColor: colors.neon['100'],
                boxShadow: 'none',
            },
            _focusVisible: {
                outlineWidth: '3px',
                outlineColor: colors.neon['50'],
                backgroundColor: colors.neon['100'],
                boxShadow: 'none',
            },
            _hover: {
                backgroundColor: colors.neon['80'],
                borderColor: colors.neon['80'],
                transition: 'background 0.3s, color 0.3s, border 0.3s',
                _disabled: {
                    backgroundColor: colors.neon['50'],
                },
            },
            _disabled: {
                backgroundColor: colors.neon['50'],
                pointerEvents: 'none',
            },
            _active: {
                backgroundColor: colors.neon['130'],
                outline: 'none',
            },
        }
    }),
    secondary: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: 'white',
            backgroundColor: colors.green.default,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.green.default,
            _focus: {
                outlineWidth: '3px',
                outlineColor: colors.stroke.light,
                boxShadow: 'none',
            },
            _hover: {
                backgroundColor: colors.green.light,
                borderColor: colors.green.light,
                transition: 'background 0.3s, color 0.3s, border 0.3s',
            },
            _disabled: {
                backgroundColor: 'captions.disabled',
            },
            _active: {
                backgroundColor: colors.green.light,
                outline: 'none',
            },
        }
    }),
    tertiary: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.green.default,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.stroke.light,
            _focus: {
                outlineWidth: '3px',
                outlineColor: colors.stroke.light,
                boxShadow: 'none',
                backgroundColor: colors.captions.dark,
                borderColor: 'transparent',
            },
            _hover: {
                backgroundColor: colors.green.transparent_3,
                borderColor: colors.stroke.light,
                transition: 'background 0.3s, color 0.3s, border 0.3s',
            },
            _disabled: {
                backgroundColor: 'colors.stroke.light',
                pointerEvents: 'none',
            },
            _active: {
                backgroundColor: colors.bg['green10'],
                outline: 'none',
            },
        }
    }),
    'tertiary-dark': defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.beige.background,
            backgroundColor: colors.green.default,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.stroke.dark,
            _focus: {
                outlineWidth: '4px',
                outlineColor: colors.stroke.dark,
                boxShadow: 'none',
                backgroundColor: colors.bg.beige10,
                borderColor: 'transparent',
            },
            _hover: {
                backgroundColor: colors.bg['beige6'],
                borderColor: colors.stroke.dark,
                transition: 'background 0.3s, color 0.3s, border 0.3s',
            },
            _disabled: {
                backgroundColor: 'colors.stroke.light',
                pointerEvents: 'none',
                color: colors.captions.dark,
            },
            _active: {
                backgroundColor: colors.bg.beige12,
                outline: 'none',
            },
        }
    }),
    ghost: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.green.default,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            _focus: {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: colors.bg['green6'],
                outlineWidth: '3px',
                outlineColor: colors.stroke.light,
            },
            _hover: {
                backgroundColor: colors.bg['green6'],
                transition: 'background 0.3s, color 0.3s, border 0.3s',
            },
            _disabled: {
                backgroundColor: 'captions.disabled',
            },
            _active: {
                outline: 'none',
                backgroundColor: colors.bg['green10'],
            },
        }
    }),
    'ghost-dark': defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.beige.background,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            _focus: {
                outlineWidth: '4px',
                outlineColor: colors.stroke.dark,
                boxShadow: 'none',
                backgroundColor: colors.bg.beige10,
                borderColor: 'transparent',
            },
            _hover: {
                backgroundColor: colors.bg['beige10'],
                transition: 'background 0.3s, color 0.3s, border 0.3s',
            },
            _disabled: {
                backgroundColor: 'captions.disabled',
            },
            _active: {
                outline: 'none',
                backgroundColor: colors.captions.dark,
            },
        }
    }),
    white: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            color: colors.beige.default,
            bgColor: 'white',
            outline: 'none',
            transition: 'border 0.3s',
            _focus: {
                outlineWidth: '4px',
                outlineColor: colors.stroke.dark,
                borderColor: 'transparent',
            },
            _disabled: {
                bgColor: 'white !important',
            },
            _active: {
                bgColor: 'white',
            },
        }
    }),
    yellow: defineStyle(() => {
        return {
            color: 'green.default',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'yellow.100',
            bgColor: 'yellow.100',
        }
    }),
    'bank-details': defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            backgroundColor: colors.beige.darkened,
            color: colors.grey.grey2,
            height: '36px',
            fontSize: '16px',
            _hover: {
                backgroundColor: colors.beige.darkened_shade,
            },
        }
    }),
    'term-picker': defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            rounded: '12px',
            borderWidth: '1px',
            bgColor: 'white',
            borderColor: colors.green.transparent_10,
            _hover: {
                borderColor: colors.green.default,
            },
            transition: 'border .25s ease',
            '&::before': {
                content: '""',
                w: '100%',
                h: '100%',
                rounded: '13px',
                transition: 'opacity .25s ease',
                borderWidth: '12px',
                border: '2px solid',
                borderColor: 'green.default',
                position: 'absolute',
                opacity: 0,
            },
            _checked: {
                '&::before': {
                    opacity: 1,
                },
            },
        }
    }),
}

export const Button = defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        variant: 'primary',
        colorScheme: 'primary',
        size: 'lg',
    },
})
