import qs, { StringifiableRecord } from 'query-string'

export const addQueryParams = (url: string, params?: object) => {
    return qs.stringifyUrl({ url, query: params as StringifiableRecord })
}

// ensure an scheme exists at the start of the url and add one if it doesn't
export const ensureScheme = (url: string, scheme = 'https') => {
    return url.startsWith(`${scheme}://`) ? url : `${scheme}://${url}`
}

export const safeConcatenate = (urlA: string, urlB: string): string => {
    // Ensure urlB starts with a slash if it doesn't already
    if (!urlB.startsWith('/')) {
        urlB = '/' + urlB
    }

    // Create a new URL object based on urlA
    const baseUrl = new URL(urlA)

    // Combine the paths from urlA and urlB
    const combinedPath = baseUrl.pathname.replace(/\/+$/, '') + urlB

    // Set the combined path to the baseUrl
    baseUrl.pathname = combinedPath

    return baseUrl.toString()
}
