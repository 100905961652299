import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    checkboxAnatomy.keys,
)

const baseStyle = definePartsStyle({
    label: {},
    control: {
        borderWidth: '1px',
    },
})

const sizes = {
    md: definePartsStyle({
        control: defineStyle({
            boxSize: '24px',
        }),
        label: defineStyle({
            fontSize: '14px',
            marginLeft: '8px',
        }),
    }),
}

const variants = {
    primary: definePartsStyle(({ theme }) => {
        const { captions, green, bg, stroke } = theme.colors

        return {
            control: defineStyle({
                rounded: '4px',
                borderColor: captions.light,
                _hover: {
                    borderColor: green.default,
                },
                _focus: {
                    borderColor: green.default,
                },
                _checked: {
                    bgColor: bg.neon10,
                    borderColor: bg.neon10,
                    color: green.default,
                    _hover: {
                        bgColor: bg.neon10,
                        borderColor: green.default,
                    },
                    _focus: {
                        borderColor: green.default,
                    },
                    _disabled: {
                        bgColor: bg.neon10,
                    },
                },
                _disabled: {
                    borderColor: stroke.light,
                    bgColor: 'transparent',
                },
            }),
        }
    }),
}

export const Checkbox = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        variant: 'primary',
    },
})
