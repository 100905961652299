import React, { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

const { publicRuntimeConfig } = getConfig()

if (publicRuntimeConfig.postHogApiKey && typeof window !== 'undefined') {
    posthog.init(publicRuntimeConfig.postHogApiKey, {
        api_host: 'https://eu.posthog.com',
    })
}

const Provider = (props: { children: React.ReactNode }) => {
    const router = useRouter()
    const { data: session } = useSession()

    // first time
    useEffect(() => {
        const handleRouteChange = () => posthog.capture('$pageview')

        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [])

    useEffect(() => {
        posthog.identify(session?.user?.id, {
            type: session?.user?.type,
            name: session?.user?.name,
        })
    }, [session])

    return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>
}

export default Provider
