// Numbered
const neon = {
    50: '#ABE59E',
    80: '#77E46E',
    100: '#55DD4A',
    130: '#3B9B34',
    150: '#237721',
}

const turquoise = {
    50: '#AEDEE5',
    80: '#96DAEB',
    100: '#73D3EB',
    130: '#1CA8D4',
    150: '#0D758F',
}

const blue = {
    50: '#B8D5FF',
    80: '#8DBAFF',
    100: '#70A9FF',
    130: '#528ADE',
    150: '#2560C5',
}

const yellow = {
    50: '#FFF39F',
    80: '#FFF175',
    100: '#FFED52',
    130: '#E5CA0A',
    150: '#B29C00',
}

const orange = {
    50: '#FFAA85',
    80: '#FF9466',
    100: '#F0652A',
    130: '#D04417',
    150: '#B83B12',
    highlighted: '#B39D00',
}

// Not numbered
const green = {
    default: '#122315',
    light: '#203D25',
    dark: '#0B150D',
    transparent_3: '#12231508',
    transparent_10: '#1223151A',
    transparent_18: '#1223152E',
    transparent_30: '#1223154D',
    transparent_48: '#1223157A',
    transparent_60: '#12231599',
}

const beige = {
    background: '#F3F0EC',
    highlighted: '#FDFCFB',
    icons: '#E3DED9',
    darkened: 'hsl(30 20% 90%)',
    darkened_shade: 'hsl(30 20% 80%)',
}

const stroke = {
    light: '#1223152e',
    dark: '#F3F0EC4d',
    icons: '#6C756B',
    icons_light: '#AFB2AB',
}

const captions = {
    light: '#12231599',
    dark: '#F3F0EC99',
    disabled: '#1223154d',
    placeholder: '#1223157a',
}

const bg = {
    green3: '#1223158',
    green6: '#122315f',
    green10: '#1223151a',
    neon10: '#55DD4A',
    beige12: '#F3F0EC1f',
    beige10: '#F3F0EC1a',
    beige6: '#F3F0EC0f',
    blue10: '#2560C51A',
}

const grey = {
    grey1: '#AFB2AB',
    grey2: '#6C756B',
}

//
export const colors = {
    neon,
    turquoise,
    blue,
    yellow,
    orange,
    green,
    beige,
    stroke,
    captions,
    bg,
    grey,
}
