import { ComponentStyleConfig } from '@chakra-ui/react'

export const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        color: 'green.default',
    },
    sizes: {
        md: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '28px',
        },
    },
    defaultProps: {
        size: 'md',
    },
}
