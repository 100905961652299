import localFont from 'next/font/local'

const deacon = localFont({
    src: [{ path: './../public/fonts/Deacon/Deacon-Black.woff2', weight: '900' }],
    display: 'swap',
})

const graphik = localFont({
    src: [
        { path: './../public/fonts/Graphik/Graphik-Regular.woff2', weight: '400' },
        { path: './../public/fonts/Graphik/Graphik-Medium.woff2', weight: '500' },
    ],
    display: 'swap',
})

export const fonts = {
    Deacon: `${deacon.style.fontFamily}, 'Deacon'`,
    Graphik: `${graphik.style.fontFamily}, 'Graphik'`,
    body: `${graphik.style.fontFamily}, 'Graphik', 'Open Sans', sans-serif`,
}
