import { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { setUser } from '@sentry/nextjs'

export function SentryUserManager() {
    const { data: session } = useSession()

    useEffect(() => {
        if (session) {
            const user = session.user
            setUser({
                email: user.email,
                username: user.name,
                id: user.id,
                companyId: user.companyId,
            })
        }
    }, [session])

    return null
}
