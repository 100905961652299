import { ChakraProvider } from '@chakra-ui/react'
import { SessionProvider as AuthProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import { SpeedInsights } from '@vercel/speed-insights/next'
import PostHogProvider from 'pages/providers/posthog'
import { routes } from 'routes'
import { trpc } from 'lib/trpc'
import { SentryUserManager } from 'components/sentry/SentryUserManager'
import { customTheme } from 'theme/theme'

const { publicRuntimeConfig } = getConfig()

const LazyConfigureGocardlessEventRedirectionBanner = dynamic(
    () => import('components/gocardless/ConfigureGocardlessEventRedirectionBanner'),
    { loading: () => null, ssr: false },
)

const KikinHead = () => {
    return (
        <Head>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#6ad762" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    )
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const router = useRouter()

    const isProdEnvironment = process.env.NODE_ENV === 'production'

    if (router.pathname === routes.calculator()) {
        return <Component {...pageProps} />
    }

    return (
        <>
            {publicRuntimeConfig.showGocardlessConfigRedirectBanner && (
                <LazyConfigureGocardlessEventRedirectionBanner />
            )}
            <NextNProgress color={customTheme.colors.neon[100]} showOnShallow={false} />
            <KikinHead />
            <AuthProvider session={session}>
                <PostHogProvider>
                    <>
                        <SentryUserManager />
                        <ChakraProvider theme={customTheme}>
                            <Component {...pageProps} />
                            <SpeedInsights />
                        </ChakraProvider>
                    </>
                </PostHogProvider>
            </AuthProvider>
        </>
    )
}

export default trpc.withTRPC(MyApp)
